<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="23.999"
    viewBox="0 0 18 23.999"
  >
    <g transform="translate(0 0)">
      <g class="a" transform="translate(0 0)">
        <path
          class="b"
          d="M3.414.4l13.608,9.658a2.423,2.423,0,0,1,0,3.89L3.414,23.6A2.194,2.194,0,0,1,0,21.658V2.342A2.194,2.194,0,0,1,3.414.4Z"
        />
        <path
          class="c"
          d="M 2.202260971069336 2.000320434570312 C 2.175739288330078 2.000320434570312 2 2.064010620117188 2 2.341999053955078 L 2 21.65800094604492 C 2 21.93598937988281 2.175739288330078 21.99967956542969 2.202260971069336 21.99967956542969 C 2.209600448608398 21.99967956542969 2.229570388793945 21.99110984802246 2.256450653076172 21.97203063964844 L 15.86485004425049 12.31373977661133 C 15.98682975769043 12.22721004486084 16 12.06550979614258 16 12 C 16 11.93449020385742 15.98682975769043 11.77278995513916 15.86445045471191 11.68597030639648 L 2.25609016418457 2.027719497680664 C 2.229579925537109 2.008890151977539 2.209600448608398 2.000320434570312 2.202260971069336 2.000320434570312 M 2.20225715637207 0.0003204345703125 C 2.610721588134766 0.0003204345703125 3.029569625854492 0.1240253448486328 3.414000511169434 0.3969993591308594 L 17.02199935913086 10.05500030517578 C 18.32600021362305 10.97999954223633 18.32600021362305 13.02000045776367 17.02199935913086 13.94499969482422 L 3.414000511169434 23.60300064086914 C 3.029659271240234 23.87591171264648 2.610631942749023 23.99967956542969 2.20225715637207 23.99967956542969 C 1.060396194458008 23.99967956542969 0 23.03318023681641 0 21.65800094604492 L 0 2.341999053955078 C 0 0.9669342041015625 1.060504913330078 0.0003204345703125 2.20225715637207 0.0003204345703125 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "StartFormat",
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke-miterlimit: 10;
}
.b,
.c {
  stroke: none;
}
.c {
  fill: #2e323b;
}
</style>