<template>
  <div class="tour-overview" v-if="loaded && pool != null">
    <div class="tour-overview-header">
      <div class="breadcrumbs">
        <div>
          <div class="home-icon">
            <router-link
              :to="{
                name: 'events',
              }"
            >
              <Home />
            </router-link>
            <p class="divider">/</p>
          </div>
          <template v-if="!oneOffEvent">
            <router-link
              class="tour-name"
              :to="{
                name: 'tournamentoverview-standings',
                params: { tourId: eventData.tour.id },
              }"
            >
              <p>Tour overview</p>
            </router-link>
            <p class="divider">/</p>
          </template>

          <router-link :to="{ name: 'public-event-results' }">
            <p>Event overview</p>
          </router-link>
          <p class="divider">/</p>

          <p>
            R{{ roundNumber
            }}<template v-if="multiPoolEvent">P{{ poolLetter }}</template>
            <template v-if="$route.name == 'public-event-pool-results'">
              results
            </template>
            <template v-if="$route.name == 'public-event-pool-groups'">
              groups
            </template>
            <template v-if="$route.name == 'public-event-pool-stats'">
              stats
            </template>
            <template v-if="$route.name == 'public-event-pool-compare'">
              compare
            </template>
          </p>
        </div>
      </div>
      <div class="left">
        <h2>
          Round {{ roundNumber }}
          <span v-if="multiPoolEvent"> - Pool {{ poolLetter }}</span>

          <span class="status-label grass" v-if="pool.status == 'OPEN'"
            >Live</span
          >
          <span
            class="status-label strom"
            v-else-if="pool.status == 'COMPLETED'"
            >Finished</span
          >
          <span class="status-label sunset" v-else>Upcoming</span>
        </h2>
        <p class="date-info">
          {{ poolStartTime }}<span>•</span>
          {{ pool.startMethod == "SHOTGUN" ? "Shotgun" : "Teetimes" }}
        </p>
        <div class="course-info">
          <p>{{ pool.layoutVersion.layout.course.name }}</p>
          <span>•</span>
          <p>
            {{ pool.layoutVersion.layout.name }}
            <Verified
              v-if="pool.layoutVersion.layout.course.type == 'PUBLIC'"
            />
          </p>
        </div>

        <span class="status-label grass" v-if="pool.status == 'OPEN'"
          >Live</span
        >
        <span class="status-label strom" v-else-if="pool.status == 'COMPLETED'"
          >Finished</span
        >
        <span class="status-label sunset" v-else>Upcoming</span>
      </div>
      <div class="right">
        <div class="event-date">
          <span class="icon-wrapper">
            <CalendarIcon />
          </span>
          {{ poolStartTime }}
        </div>
        <div class="start-method">
          <span class="icon-wrapper"> <StartFormat /> </span
          >{{ pool.startMethod.toLowerCase() }}
        </div>
      </div>

      <nav class="rounds-left">
        <ul class="tour-nav" :class="navClass()">
          <router-link :to="{ name: 'public-event-pool-results' }">
            <li class="results">Results</li>
          </router-link>
          <router-link :to="{ name: 'public-event-pool-groups' }">
            <li class="groups">Groups</li>
          </router-link>
          <router-link :to="{ name: 'public-event-pool-stats' }">
            <li class="stats">Stats</li>
          </router-link>
          <router-link :to="{ name: 'public-event-pool-compare' }">
            <li class="compare">Compare</li>
          </router-link>
        </ul>
      </nav>
      <nav class="rounds-right">
        <ul class="tour-nav">
          <template
            v-for="(round, roundIndex) in eventData.rounds"
            v-if="eventData.rounds.length > 1"
          >
            <li
              v-for="(pool, poolIndex) in round.pools"
              :key="pool.id"
              :class="{
                active:
                  pool.id == $route.params.poolId &&
                  $route.name != 'public-event-standings',
                small: round.pools.length == 1,
              }"
              @click="goToPool(pool.id)"
            >
              {{ roundPoolFormat(round, roundIndex, poolIndex) }}
            </li>
          </template>
        </ul>
      </nav>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import StartFormat from "@/assets/icons/StartFormat";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import Verified from "@/assets/icons/Verified";
import Home from "@/assets/icons/Home";
export default {
  name: "PublicEventRound",
  components: {
    CalendarIcon,
    StartFormat,
    Verified,
    Home,
  },
  data() {
    return {
      loaded: false,
      eventData: null,
    };
  },
  watch: {},
  computed: {
    poolStartTime() {
      if (this.pool) {
        return moment(this.pool.date).format("D MMM [at] HH:mm");
      }
      return "";
    },
    pool() {
      let pagePool = null;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool, poolIndex) => {
          if (pool.id == this.$route.params.poolId) {
            pagePool = pool;
          }
        });
      });

      return pagePool;
    },
    multiPoolEvent() {
      return this.eventData.rounds[0].pools.length > 1 ? true : false;
    },
    roundNumber() {
      let roundNumber = 0;

      this.eventData.rounds.forEach((round, roundIndex) => {
        round.pools.forEach((pool) => {
          if (pool.id == this.$route.params.poolId) {
            roundNumber = roundIndex + 1;
          }
        });
      });

      return roundNumber;
    },
    poolLetter() {
      let poolLetter = 0;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool, poolIndex) => {
          if (pool.id == this.$route.params.poolId) {
            poolLetter = this.$store.state.poolConversion[poolIndex];
          }
        });
      });

      return poolLetter;
    },
    route() {
      return this.$route;
    },
    showRoundsMenu() {
      return (
        this.$route.path.includes("/rounds/") ||
        this.$route.name == "public-event-eventstandings"
      );
    },

    oneOffEvent() {
      return this.eventData.tour.events.length > 1 ? false : true;
    },
  },
  methods: {
    navClass() {
      if (this.$route.name == "public-event-pool-results") return "results";
      if (this.$route.name == "public-event-pool-groups") return "groups";
      if (this.$route.name == "public-event-pool-stats") return "stats";
      if (this.$route.name == "public-event-pool-compare") return "compare";

      return "";
    },
    roundPoolFormat(round, roundIndex, poolIndex) {
      let returnString = "";

      if (round.pools.length == 1) {
        returnString = "R" + (roundIndex + 1);
      } else {
        returnString = `R${roundIndex + 1}P${
          this.$store.state.poolConversion[poolIndex]
        }`;
      }

      return returnString;
    },
    goToPool(poolId) {
      let params = this.$route.params;
      let name = this.$route.name;
      if (params.poolId !== poolId) {
        params.poolId = poolId;
        this.$router.push({ name, params });
      }
    },
    async loadData() {
      let tourInfo = await this.$axios({
        data: {
          query: `
          {
            event(eventId: "${this.$router.currentRoute.params.eventId}") {
              id
              name
              rounds{
                id
                pools{
                  id
                  status
                  startMethod
                  date
                  layoutVersion{
                    layout{
                      id
                      name
                      type
                      course{
                        name
                        type
                      }
                    }
                  }
                }
              }
              tour {
                id
                name
                events {
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.eventData = tourInfo.data.data.event;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
.tour-overview-header {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  max-width: 100%;

  h2 {
    @include Gilroy-Bold;
    font-size: 28px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
  }

  .breadcrumbs {
    margin: 24px 0 36px 0;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 6px;

    .home-icon {
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        margin-bottom: 4px;
      }
    }

    div {
      display: flex;
      a {
        color: $ocean;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 14px;
        margin: 0;
        white-space: nowrap;

        &.divider {
          min-width: 12px;
          text-align: center;
        }
      }
    }
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;

    h2,
    p {
      padding-left: 6px;
      display: flex;

      align-items: center;

      svg {
        margin-left: 6px;
      }
    }
    h2 {
      justify-content: flex-start;
      padding-right: 6px;

      span {
        margin-left: 8px;
      }

      .status-label {
        margin-left: auto;
        display: inline;
      }
    }
    .status-label {
      display: none;
    }

    p {
      margin-top: 12px;
    }

    .date-info {
      margin-top: 12px;
      font-size: 14px;
      margin-bottom: 0;

      span {
        margin: 0 8px;
      }
    }
    .course-info {
      display: flex;
      align-items: center;

      p {
        padding: 0;
        font-size: 14px;
        white-space: nowrap;
        display: block;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(1) {
          max-width: 60%;
          padding-left: 6px;
        }
        &:nth-child(3) {
          max-width: 33%;
        }
      }
      border-bottom: 1px solid $sleet;
      padding-bottom: 24px;
      margin-bottom: 0;

      span {
        display: flex;
        justify-content: center;
        margin: 0 8px;
        font-size: 14px;
      }
    }
  }
  nav {
    padding: 0 6px;
    display: flex;
    width: 100%;
    margin: 24px 0 16px 0;

    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      padding: 0;
      list-style: none;
      position: relative;

      .router-link-exact-active {
        li {
          position: relative;
          &::after {
            content: "";
            height: 2px;
            background: $midnight;
            position: absolute;
            bottom: -4px;
            left: 0;
            z-index: 3;
            transition: all ease 0.3s;
            width: 100%;
          }
        }
      }

      li {
        font-size: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-right: 32px;
        &.standings {
          width: 80px;
        }
        &.rounds {
          width: 60px;
        }
        &.players {
          width: 58px;
        }
        &.details {
          width: 56px;
        }
      }
    }
    &.rounds-right {
      padding: 0 6px;
      margin: 0 0 24px 0;
      width: 100%;

      .tour-nav {
        margin-bottom: 0;
      }
      ul {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        overflow: hidden;
        flex-wrap: wrap;

        .router-link-exact-active {
          li {
            background: $ocean;
            color: white;
          }
        }

        li {
          display: flex;
          cursor: pointer;
          font-size: 14px;
          background: $mist;
          border-radius: 16px;
          padding: 6px 0;
          margin-right: 4px;
          transition: all ease 0.3s;
          width: 52px;
          margin-bottom: 14px;
          &.small {
            width: 50px;
          }
          &.active {
            cursor: default;
            background: $ocean;
            color: white;
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tour-overview-header {
    padding: 0;

    h2 {
      font-size: 48px;
    }

    .breadcrumbs {
      padding: 50px 0 40px 0;
      border-right: 1px solid $fog;
      width: 75%;
      margin: 0;
      display: flex;
      align-items: center;

      .home-icon {
        svg {
          width: 18px;
          height: auto;
        }
      }

      div {
        p {
          font-size: 16px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 24px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
        justify-content: center;
        margin-right: 10px;
      }

      .start-method {
        margin-top: 16px;
        display: flex;
        align-items: center;
        font-size: 18px;
        text-transform: capitalize;
      }

      .event-date {
        display: flex;
        align-items: center;
        font-size: 18px;
      }
    }
    .left {
      width: 75%;
      margin-top: 0;
      border-right: 1px solid $fog;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin-top: 8px;
        font-size: 18px;
        padding-left: 0;
        display: flex;
        align-items: center;
        svg {
          margin-left: 7px;
        }
      }

      h2 {
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .status-label {
          display: none;
        }
      }

      .status-label {
        display: inline-block;
        width: auto;
      }

      .date-info {
        display: none;
      }
      .part-of-tour {
        display: block;
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
      }
      .finished-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
      }
      .ongoing-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
        @keyframes pulse {
          0% {
            opacity: 1;
            transform: scale(0.3);
          }

          100% {
            opacity: 0;
            transform: scale(1);
          }
        }

        .standings-graphic {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          flex-direction: row;
          margin-left: 32px;
          margin-right: 10px;

          span {
            display: block;
            background: $ocean;
            width: 7px;
            border-radius: 2px 2px 0px 0;
            transition: all ease 0.3s;
          }

          .left-bar {
            height: 14px;
          }
          .middle-bar {
            height: 24px;
            margin-right: 2px;
            margin-left: 2px;
          }
          .right-bar {
            height: 18px;
          }
        }
        .view-standings {
          color: $ocean;

          &:hover {
            text-decoration: underline;
          }
        }
        .live-icon {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 4px;

          .solid {
            display: flex;
            width: 12px;
            height: 12px;
            background-color: $grass;
            border-radius: 50%;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .pulsing {
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            span {
              display: flex;
              width: 32px;
              height: 32px;
              background-color: $grass;
              border-radius: 50%;
              animation-name: pulse;
              animation-duration: 1.2s;
              animation-iteration-count: infinite;
            }
          }
        }
      }

      .course-info {
        width: 100%;
        border: none;
        font-size: 16px;
        p {
          font-size: 18px;
          padding-left: 0 !important;
        }
      }
    }

    nav {
      padding: 0 6px;
      display: flex;
      width: 100%;
      margin: 48px 0 64px 0;
      overflow: hidden;
      position: relative;
      border-top: 1px solid $fog;
      border-bottom: 1px solid $fog;
      &.rounds-left {
        padding: 0;
        width: 35%;

        ul {
          margin: 0;
          overflow: visible;
          li {
            padding: 0 0 20px 0;
            height: 100%;
            overflow: visible;

            &.results {
              width: 62px;
            }
            &.groups {
              width: 64px;
            }
            &.stats {
              width: 48px;
            }
            &.compare {
              width: 80px;
            }
          }
        }
      }

      &.rounds-right {
        padding: 14px 0px 0 0;
        margin: 48px 0 64px 0;
        width: 64%;
        ul {
          justify-content: flex-end;
          align-items: center;
          padding: 0;

          .router-link-exact-active {
            li {
              background: $ocean;
              color: white;
            }
          }

          li {
            cursor: pointer;
            font-size: 16px;
            background: $mist;
            border-radius: 16px;
            margin-right: 12px;
            transition: all ease 0.3s;
            width: 52px;

            &.small {
              width: 50px;
            }
            &.active {
              cursor: default;
              background: $ocean;
              color: white;
            }
            &::after {
              display: none;
            }
          }
        }
      }
      ul {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 16px 0 0 0;
        list-style: none;
        position: relative;
        &::after {
          content: "";
          height: 3px;
          background: $midnight;
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: 3;
          transition: all ease 0.3s;
        }

        &.results {
          &::after {
            width: 62px;
            left: 0;
          }
        }
        &.groups {
          &::after {
            width: 64px;
            left: 94px;
          }
        }
        &.stats {
          &::after {
            width: 48px;
            left: 190px;
          }
        }
        &.compare {
          &::after {
            width: 80px;
            left: 270px;
          }
        }

        li {
          font-size: 18px;
          padding-bottom: 16px;
          margin-right: 32px;
          padding: 0 4px 16px 4px;
          transition: all ease 0.3s;
          &:hover {
            &::after {
              content: "";
              height: 3px;
              background: $sleet;
              position: absolute;
              bottom: 0px;
              left: 0;
              z-index: 2;
              transition: all ease 0.3s;
              width: 100%;
            }
          }
        }

        .router-link-exact-active {
          li {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
